import React from 'react';
import logo from './a/s/uglyclown.svg';
import ecola from "./a/i/ecola.png";
import stupidbutt from "./a/i/butt.png";
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Elkhorn Puppy Cuddles Association of America <br/><br/>

                    Woof!
                </p>

                <p className="StupidButt">
                    <img src={stupidbutt}/>
                </p>

                <p className="Sponsor">
                    Sponsored by
                    <br/>
                    <img src={ecola}/>
                </p>

            </header>
        </div>
    );
}

export default App;
